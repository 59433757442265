<template>
  <div>
    <!-- JOB DETAILS -->
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">
              Job #{{job.id}} for
              <a v-if="!$hasRole('Customer')" href="javascript:void(0);" @click="$router.push(`/p/clients/${client.id}`)">{{client.name}}</a><span v-else>{{client.name}}</span>:
              <a v-if="!$hasRole('Customer')" href="javascript:void(0);" @click="$router.push(`/p/sites/${site.id}`)">{{site.name}}</a><span v-else>{{site.name}}</span>
              <!-- <span v-if="!$hasRole('Customer')" style="cursor: pointer;" @click="deleteJob"><i class='bx bxs-trash text-danger'></i></span> -->
              <button class="btn btn-sm btn-primary float-end" @click="$router.push({name: 'admin.sites.view', params: {id: site.id}})">Go to Site</button>
              <button class="btn btn-sm btn-primary float-end" v-if="job.status == 1" @click="downloadJobSheet">Download Job Sheet</button>
              <button class="btn btn-sm btn-success float-end" v-if="job.status == 1" @click="downloadJobClearance">Download Job Clearance</button>
            </h5>
            <div class="row">
              <div class="col-6">
                <h6>
                  Status
                  <select :disabled="$hasRole('Customer')" v-model="job.status" :value="job.status" style="max-width: 200px;" @change="updateStatus" class="form-select form-control-sm d-inline-block ms-2">
                    <option value="0">TBA</option>
                    <option value="5">Requires Prep</option>
                    <option value="6">Quoted - Chase</option>
                    <option value="1">Complete</option>
                    <option value="2">Assigned</option>
                    <option value="3">Rejected</option>
                    <option value="7">Quoted - Close</option>
                  </select>
                </h6>

              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6">
                <h6>
                  Type
                  <select :disabled="$hasRole('Customer')" v-model="job.type" :value="job.type" style="max-width: 200px;" @change="updateType" class="form-select form-control-sm d-inline-block ms-2">
                    <option value="Installation">Installation</option>
                    <option value="Service">Service</option>
                    <option value="Maintenance">Maintenance</option>
                    <option value="Repair">Repair</option>
                    <option value="Investigation">Investigation</option>
                    <option value="Remote">Remote</option>
                    <option value="Delivery">Delivery</option>
                    <option value="Phone / Email">Phone / Email</option>
                    <option value="Snagging">Snagging</option>
                    <option value="Planning">Planning</option>
                    <option value="Survey">Survey</option>
                  </select>
                </h6>
              </div>
            </div>
            <div v-if="job" class="row mt-2">
              <div class="col-6">
                <h6>Source Information</h6>
                <p v-if="!toggles.editReportedBy"><strong>Reported By: </strong> {{job.reported_by || "No Record."}} <i v-if="!$hasRole('Customer')" class='bx bx-edit-alt' style="cursor: pointer;" @click="toggles.editReportedBy = !toggles.editReportedBy"></i></p>
                <p v-else>
                  <strong>Reported By: </strong>
                  <input type="text" class="form-control form-control-sm d-inline-block" v-model="job.reported_by" />
                  <i class='bx bx-check' style="cursor: pointer;" @click="saveReportedBy"></i>
                  <i class='bx bx-x' style="cursor: pointer;" @click="toggles.editReportedBy = !toggles.editReportedBy"></i>
                </p>
                <p><strong>Created By: </strong> {{job.created_by?.fname || ""}} {{job.created_by?.lname || ""}}</p>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6">
                <h6>Service Location</h6>
                <table class="table table-borderless mb-0" style="cursor: pointer;" @click="openMaps">
									<tbody>
										<tr>
											<th scope="row" style="width: 1rem;"><i class='bx bx-map'></i></th>
											<td>{{site.street}}<br/>{{site.town}}<br/>{{site.county}}<br/>{{site.postcode}}</td>
										</tr>
                    <tr v-if="numberOfContacts > 0">
											<th scope="row" style="width: 1rem;"><i class='bx bx-mail-send'></i></th>
											<td>
                        <span v-for="contact in site.contacts" :key="contact.id">{{contact.email}}<br/></span>
                      </td>
										</tr>
                    <tr v-if="numberOfContacts > 0">
											<th scope="row" style="width: 1rem;"><i class='bx bx-phone'></i></th>
											<td>
                        <span v-for="contact in site.contacts" :key="contact.id">{{contact.telephone}}<br/></span>
                      </td>
										</tr>
                    <tr v-if="site.telephone">
											<th scope="row" style="width: 1rem;"><i class='bx bx-phone'></i></th>
											<td>{{site.telephone}}</td>
										</tr>
									</tbody>
								</table>

                <button v-if="!$hasRole('Customer')" class="btn btn-sm btn-primary" @click="$router.push({name: 'admin.sites.view', params: {id: site.id}})">Go to Site</button>
                <button v-if="$hasRole('Support')" class="btn btn-sm ms-2 btn-warning" style="float: right;" data-bs-toggle="modal" data-bs-target="#migrateJobSite">Migrate Job</button>
              </div>
              <div class="col-6">
                <h6>Bill To</h6>
                <table class="table table-borderless mb-0">
									<tbody>
										<tr>
											<th scope="row" style="width: 1rem;"><i class='bx bx-map'></i></th>
											<td>{{client.name}}<br/>{{client.street}}<br/>{{client.town}}<br/>{{client.county}}<br/>{{client.postcode}}</td>
										</tr>
                    <tr v-if="client.telephone">
											<th scope="row" style="width: 1rem;"><i class='bx bx-phone'></i></th>
											<td>{{client.telephone}}</td>
										</tr>
									</tbody>
								</table>
                <button v-if="!$hasRole('Customer')" class="btn btn-sm btn-primary" @click="$router.push({name: 'admin.clients.view', params: {id: site.client_id}})">Go to Client</button>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <h5>Job summary  <i v-if="!$hasRole('Customer')" class='bx bx-edit-alt' style="cursor: pointer;" @click="toggles.editDescription = !toggles.editDescription"></i></h5>
                    <pre v-if="!toggles.editDescription">{{job.description}}</pre>
                    <div v-else>
                      <p class="mb-0">Try pressing <code>CTRL + S</code> to save your changes whilst editing the description!</p>
                      <textarea @keydown.ctrl.s.prevent.stop="saveDescription" rows="5" class="form-control" v-model="job.description"></textarea>
                      <button class="btn btn-sm btn-success float-end" @click="saveDescription">Save Changes</button>
                    </div>
                  </div>
                </div>
                <!-- <div class="row mt-2">
                  <div class="col-12">
                    <h5>Related Jobs</h5>
                    <ul>
                      <li><a href="javascript:void(0);" class="text-info"><i class="bx bx-run"></i> Job #2987</a></li>
                      <li><a href="javascript:void(0);" class="text-info"><i class="bx bx-run"></i> Job #2973</a></li>
                      <li><a href="javascript:void(0);" class="text-info"><i class="bx bx-run"></i> Job #2197</a></li>
                    </ul>
                  </div>
                </div> -->
                <div class="row mt-2">
                  <div class="col-12">

                    <h5>Assigned Users <i v-if="!$hasRole('Customer') && job.status !== 5" class='bx bx-edit-alt' style="cursor: pointer;"  data-bs-toggle="modal" data-bs-target="#manageJobUsersModal" @click="focusUserFilter"></i> <div v-if="job.related_jobs > 0" style="cursor: pointer;" @click="loadRelatedJobs" class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class='bx bxs-flag-alt me-1 text-warning'></i> {{job.related_jobs}} Other Jobs</div></h5>

                    <div v-for="user of users" :key="'user-' + user.id" class="chip chip-lg bg-primary text-white">
                      <a :href="'/p/users/' + user.id + '/jobs'">{{user.fname}} {{user.lname}} (<VTime :datetime="user.date" format="dd/MM/yyyy"></VTime>) </a><span class="closebtn" @click="removeUser(user)">×</span>
                    </div>
                    
                    <p class="text-warning" v-show="job.status == 5">Jobs that are set to Requires Prep cannot be assigned to a user!</p>

                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12">
                    <h5>Completed Works  <i v-if="!$hasRole('Customer')" class='bx bx-edit-alt' style="cursor: pointer;" @click="toggles.editCompletedWorks = !toggles.editCompletedWorks"></i></h5>
                    <p v-if="!toggles.editCompletedWorks">{{job.completion_notes}}</p>
                    <div v-else>
                      <p class="mb-0">Try pressing <code>CTRL + S</code> to save your changes!</p>
                      <textarea @keydown.ctrl.s.prevent.stop="saveCompletionNotes" class="form-control" v-model="job.completion_notes"></textarea>
                      <button class="btn btn-sm btn-success float-end" @click="saveCompletionNotes">Save Changes</button>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12">
                    <h5>Not Completed Works  <i v-if="!$hasRole('Customer')" class='bx bx-edit-alt' style="cursor: pointer;" @click="toggles.editNotCompletedWorks = !toggles.editNotCompletedWorks"></i></h5>
                    <p v-if="!toggles.editNotCompletedWorks">{{job.not_completed_notes}}</p>
                    <div v-else>
                      <p class="mb-0">Try pressing <code>CTRL + S</code> to save your changes!</p>
                      <textarea @keydown.ctrl.s.prevent.stop="saveCompletionNotes" class="form-control" v-model="job.not_completed_notes"></textarea>
                      <button class="btn btn-sm btn-success float-end" @click="saveCompletionNotes">Save Changes</button>
                    </div>
                  </div>
                </div>

                <div v-if="job.status === 1 && !$hasRole('Customer')" class="row mt-2">
                  <div class="col-12">
                    <button class="btn btn-primary" @click="$refs.jobnewjobform.show()">Create a New Job</button>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row" v-if="!$hasRole('Customer')">
                  <div class="col-12">
                    <h5>Invoicing</h5>
                    <select v-model="job.invoiced" :value="job.invoiced" style="max-width: 200px;" @change="updateInvoiced" class="form-select form-control-sm d-inline-block ms-2">
                      <option value="0">Not Invoiced</option>
                      <option value="1">Invoiced</option>
                      <option value="2">Invoiced (Warranty / Maintenance)</option>
                    </select>

                    <p v-if="!toggles.editQuoteNumber"><strong>Quote Number: </strong> {{job.quote_number || "No Record."}} <i   v-if="!$hasRole('Customer')" class='bx bx-edit-alt' style="cursor: pointer;" @click="toggles.editQuoteNumber = !toggles.editQuoteNumber"></i></p>
                    <p v-else>
                      <strong>Quote Number: </strong>
                      <input type="text" class="form-control form-control-sm d-inline-block" v-model="job.quote_number" />
                      <i class='bx bx-check' style="cursor: pointer;" @click="saveQuoteNumber"></i>
                      <i class='bx bx-x' style="cursor: pointer;" @click="toggles.editQuoteNumber = !toggles.editQuoteNumber"></i>
                    </p>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-12">
                    <h5>Linked Jobs</h5>
                    <router-link class="d-block" v-for="job in linkedJobs" :key="job" :to="{name: 'admin.jobs.view', props: {id: job}, params: {id: job}}"><span class="link"><i class='bx bx-link'></i> Job #{{job}}</span></router-link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>  

    <!-- TAB HEADERS -->
    <div class="row mt-2">

      <div class="col-12">

        <ul class="nav nav-pills mb-3" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" data-bs-toggle="pill" href="#tab-notes" role="tab" aria-selected="true">
              <div class="d-flex align-items-center">
                <div class="tab-title">Notes ({{notes.length}})</div>
              </div>
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" data-bs-toggle="pill" href="#tab-checklist" role="tab" aria-selected="false">
              <div class="d-flex align-items-center">
                <div class="tab-title">Checklist</div>
              </div>
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" data-bs-toggle="pill" href="#tab-files" role="tab" aria-selected="false">
              <div class="d-flex align-items-center">
                <div class="tab-title">Files</div>
              </div>
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" data-bs-toggle="pill" href="#tab-images" role="tab" aria-selected="false">
              <div class="d-flex align-items-center">
                <div class="tab-title">Images ({{images.length}})</div>
              </div>
            </a>
          </li>
          <li v-if="!$hasRole('Customer')" class="nav-item" role="presentation">
            <a class="nav-link" data-bs-toggle="pill" href="#tab-timeline" role="tab" aria-selected="false">
              <div class="d-flex align-items-center">
                <div class="tab-title">Timeline</div>
              </div>
            </a>
          </li>
        </ul>

      </div>

    </div>

    <!-- TAB CONTENT -->
    <div class="row mt-2">

      <div class="tab-content">
        <div class="tab-pane fade active show" id="tab-notes" role="tabpanel">
          <!-- JOB NOTES -->
          <div class="col-6">
            <div class="card">
              <div class="chat-wrapper" style="height: 340px">
                <div class="chat-content" style="top: 0px; margin-left: 0px; height: 270px;">
                  <div v-for="note in notes" :key="note.id" :class="{'chat-content-leftside': this.user.id !== note.user.id, 'chat-content-rightside': this.user.id === note.user.id}">
                    <div class="d-flex">
                      <div class="flex-grow-1 ms-2">
                        <p class="mb-0 chat-time" :class="{'text-end': this.user.id === note.user.id}">{{note.user.fname}} {{note.user.lname}}, <V-Time :datetime="note.created_at" format='dd/MM/yyyy HH:mm:ss' /></p>
                        <p :class="{'chat-left-msg': this.user.id !== note.user.id, 'chat-right-msg': this.user.id === note.user.id}">{{note.note}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="chat-footer d-flex align-items-center" style="left: 0px;">
                  <div class="flex-grow-1 pe-2">
                    <div class="input-group">
                      <input type="text" class="form-control" v-model="note" placeholder="Type a note" v-on:keyup.enter="createNote">
                    </div>
                  </div>
                </div>
                <!--start chat overlay-->
                <div class="overlay chat-toggle-btn-mobile"></div>
                <!--end chat overlay-->
              </div>
            </div>
          </div>
          <!-- JOB STOCK -->
          <!-- <div class="col-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Job Stock</h5>
                <div class="table-responsive">
                  <table class="table align-middle mb-0">
                    <thead class="table-light">
                      <tr>
                        <th>Stock id</th>
                        <th>Product</th>
                        <th>Quantity</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>#897656</td>
                        <td>
                          <div class="d-flex align-items-center">
                            <div class="recent-product-img">
                              <img src="/assets/images/xno-6080r.png" alt="">
                            </div>
                            <div class="ms-2">
                              <h6 class="mb-1 font-14">XNO-6080R</h6>
                            </div>
                          </div>
                        </td>
                        <td>x1</td>
                        <td>
                          <div class="d-flex align-items-center text-white">	<i class="bx bx-radio-circle-marked bx-burst bx-rotate-90 align-middle font-18 me-1"></i>
                            <span>In Van</span>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex order-actions">	
                            <a href="javascript:;" class=""><i class="bx bx-cog"></i></a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="tab-pane fade" id="tab-files" role="tabpanel">
          <file-manager v-if="job.id" :job="job"></file-manager>
        </div>
        <div class="tab-pane fade" id="tab-checklist" role="tabpanel">
          <div v-if="job.checklists" class="row">

            <div class="col-12">
              <div class="card">
                <div class="card-body">

                  <button href="javascript:;" class="btn btn-light float-end ms-2" data-bs-toggle="modal" data-bs-target="#assignChecklistModal">+ Assign Checklist</button>

                  <table class="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th># Items</th>
                        <th># Completed</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="checklist in job?.checklists" :key="'checklist-' + checklist.id">
                        <td>{{ checklist.name }}</td>
                        <td>{{ checklist.description }}</td>
                        <td>{{ checklist.items.length }}</td>
                        <td>{{ checklist.items.filter(item => item.answer !== null).length }}</td>
                        <td>
                          <button class="btn btn-sm btn-primary" @click="openChecklist(checklist)">View</button>
                          <button class="btn btn-sm btn-danger ms-2" @click="removeJobChecklist(checklist)">Remove</button>
                        </td>
                      </tr>
                      <tr v-if="job.checklists.length < 1">
                        <td colspan="5" class="text-center">No checklists found</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="tab-images" role="tabpanel">
          
          <div class="row">

            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <input id="fancy-file-upload" type="file" name="files" accept=".jpg, .png, image/jpeg, image/png" multiple>
                </div>
              </div>
            </div>

          </div>

          <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 mt-2">

            <div v-for="image in images" :key="'jobimage-' + image.id" class="col">
              <div class="card">
                <secure-image v-if="!image.video" @click="showImage(image)" class="card-img-top" alt="..." :id="'jobimagepic-'+image.id" :endpoint="`/v1/jobs/${this.id}/images/${image.id}`"></secure-image>
                <video v-else controls :src="`${endpoint}/v1/jobs/${this.id}/images/${image.id}?token=${$store.state.auth.token}`"></video>
                <div class="card-body">
                  <h6 v-show="!image.edit" class="card-title cursor-pointer">{{image.name || "No name on record."}}</h6>
                  <input v-show="image.edit" type="text" v-model="image.name" class="form-control">
                  <div class="clearfix">
                    <p v-show="!image.edit" class="mb-0 float-start">{{image.description || "No description on record."}}</p>
                    <textarea v-show="image.edit" v-model="image.description" class="form-control"></textarea>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <p class="mb-0 ms-auto">Uploaded by {{image.user.fname}} {{image.user.lname}} on <v-time :datetime="image.created_at" format="dd/MM/yyyy"></v-time></p>
                  </div>
                  <br/>
                  <div class="d-flex justify-content-between">
                    <button style="width: 45%;" v-show="!image.edit" class="btn btn-sm btn-danger tx-bold" @click="deleteImage(image)">DELETE</button>
                    <button style="width: 45%;" v-show="!image.edit" @click="image.edit = true;" class="btn btn-sm btn-primary">Edit</button>
                    <button style="width: 45%;" v-show="image.edit" @click="updateImage(image)" class="btn btn-sm btn-primary">Save</button>
                    <button style="width: 45%;" v-show="image.edit" @click="image.edit = false;" class="btn btn-sm btn-secondary">Cancel</button>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
        <div v-if="!$hasRole('Customer')" class="tab-pane fade" id="tab-timeline" role="tabpanel">

          <div class="container py-2">
            <div v-for="(log, index) in logs" :key="log.id" class="row">
              <div v-if="index < 1" class="col-auto text-center flex-column d-none d-sm-flex">
                <div class="row h-50">
                  <div class="col">&nbsp;</div>
                  <div class="col">&nbsp;</div>
                </div>
                <h5 class="m-2">
                  <span class="badge rounded-pill bg-light border">&nbsp;</span>
                </h5>
                <div class="row h-50">
                  <div class="col border-end">&nbsp;</div>
                  <div class="col">&nbsp;</div>
                </div>
              </div>
              <div v-if="index > 0 && index < logs.length - 1" class="col-auto text-center flex-column d-none d-sm-flex">
                <div class="row h-50">
                  <div class="col border-end">&nbsp;</div>
                  <div class="col">&nbsp;</div>
                </div>
                <h5 class="m-2">
                  <span class="badge rounded-pill bg-light border">&nbsp;</span>
                </h5>
                <div class="row h-50">
                  <div class="col border-end">&nbsp;</div>
                  <div class="col">&nbsp;</div>
                </div>
              </div>
              <div v-if="index == logs.length - 1" class="col-auto text-center flex-column d-none d-sm-flex">
                <div class="row h-50">
                  <div class="col border-end">&nbsp;</div>
                  <div class="col">&nbsp;</div>
                </div>
                <h5 class="m-2">
                  <span class="badge rounded-pill bg-light border">&nbsp;</span>
                </h5>
                <div class="row h-50">
                  <div class="col">&nbsp;</div>
                  <div class="col">&nbsp;</div>
                </div>
              </div>
              <div class="col py-2">
                <div class="card radius-15">
                  <div class="card-body">
                    <div class="float-end"><VTime :datetime="log.created_at" format="fff"></VTime></div>
                    <h4 class="card-title text-white">{{log.fname}} {{log.lname}}</h4>
                    <p class="card-text">{{log.activity}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="modal fade" id="manageJobUsersModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Assign Job to Users</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" style="max-height: 500px;">
            <div class="row">
              
              <input class="form-control mb-2" id="userFilterInput" type="text" v-model="userFilter">

              <table class="table align-middle mb-0">
								<thead class="table-light">
									<tr>
										<th>#</th>
										<th>Name</th>
                    <th>Date</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
                  <tr v-for="user in filteredUsers" :key="'auser-' + user.id">
                    <td>{{user.id}}</td>
                    <td>{{user.fname}} {{user.lname}}</td>
                    <td><input class="form-control" type="date" format="dd/MM/yyyy" :id="'date-' + user.id"></td>
                    <td><button class="btn btn-sm btn-primary" @click="addUser(user)">Add User</button></td>
                  </tr>
								</tbody>
							</table>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeManageUsersJobModal'>Close</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="imageViewModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div v-if="currentImage.id" class="modal-body" style="max-height: 500px;">
            <secure-image class="card-img-top" alt="..." :id="'jobimagepic-'+currentImage.id" :endpoint="`/v1/jobs/${this.id}/images/${currentImage.id}`"></secure-image>
            <br/>
            <h6 class="card-title cursor-pointer mt-2">{{currentImage.name || "No name on record."}}</h6>
            <div class="clearfix">
              <p class="mb-0 float-start">{{currentImage.description || "No description on record."}}</p>
            </div>
            <p class="mb-0 ms-auto">Uploaded by {{currentImage.user.fname}} {{currentImage.user.lname}} on <v-time :datetime="currentImage.created_at" format="dd/MM/yyyy"></v-time></p>
          </div>
        </div>
      </div>
    </div>

    <new-job-form v-if="job.status === 1" id="jobnewjobform" ref="jobnewjobform" :site_id="job.site_id" :description="`This job is a continuation of #${job.id}.`" :type="job.type"></new-job-form>

    <!-- Related Jobs Modal -->
    <div class="modal fade" id="relatedJobsModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Related Jobs</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" style="max-height: 500px;">
            <div class="row">

              <div v-if="loadingRelatedJobs" class="text-center">
                <div class="spinner-border" role="status"> <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <div v-else>

                <table class="table">
                  <thead>
                    <tr>
                      <th>JR #</th>
                      <th>Type</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Assigned Users</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="relatedJob of relatedJobs" :key="'related_job_' + relatedJob.id">
                      <td>#{{relatedJob.id}}</td>
                      <td>{{relatedJob.type}}</td>
                      <td>{{relatedJob.description}}</td>
                      <td>
                        <div v-if="relatedJob.status == 1" class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-success"></i>COMPLETE</div>
                        <div v-else-if="relatedJob.status == 2" class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-warning"></i>ASSIGNED</div>
                        <div v-else-if="relatedJob.status == 5" class="badge rounded-pill bg-warning p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-primary">REQUIRES PREP</i></div>
                        <div v-else class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-danger"></i>TBA</div>

                        <div v-if="relatedJob.status == 1 && (relatedJob.not_completed_notes || '').length > 10" class="badge rounded-pill bg-light p-2 text-uppercase px-3 ms-2"><i class="bx bxs-circle me-1 text-warning"></i>FURTHER WORK</div>
                      </td>
                      <td>
                        <span v-if="relatedJob.users.length < 1">None</span>
                        <ul v-else v-for="user in relatedJob.users" :key="'jobuser-' + user.id" style="list-style-type:none;">
                          <li>{{user.fname}} {{user.lname}}</li>
                        </ul>
                      </td>
                      <td>
                        <button type="button" @click="openJob(relatedJob)" class="btn btn-sm btn-outline-primary px-5">View Job <i class='bx bx-link-external' ></i></button>
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeRelatedJobsModal'>Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Assign Checklist Modal -->
    <div class="modal fade" id="assignChecklistModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Assign Checklist</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" style="max-height: 500px;">
            <div class="row">

                <table class="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th># Items</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="checklist in availableChecklists" :key="'ac-' + checklist.id">
                      <td>{{checklist.name}}</td>
                      <td>{{checklist.description}}</td>
                      <td>{{checklist.items?.length || 0}}</td>
                      <td>
                        <button type="button" @click="assignChecklist(checklist)" class="btn btn-sm btn-outline-primary px-5">Assign Checklist</button>
                      </td>
                    </tr>
                    <tr v-if="availableChecklists.length < 1">
                      <td colspan="4" class="text-center">No checklists available</td>
                    </tr>
                  </tbody>
                </table>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeAssignChecklistModal'>Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- View Checklist Modal -->
    <div class="modal fade" id="viewChecklistModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">View Checklist</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" style="max-height: 500px;">
            <div v-if="currentChecklist" class="row">

                <p>{{ currentChecklist.name }}</p>
                <p>{{ currentChecklist.description }}</p>

                <table class="table">
                  <thead>
                    <tr>
                      <th>Question</th>
                      <th>Checked?</th>
                      <th>Comment</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in currentChecklist.items" :key="'jca-' + item.id">
                      <td>{{item.question}}</td>
                      <td>
                        <i v-if="item.answer == 1" class="bx bx-check text-success"></i>
                        <i v-else-if="item.answer == 0 || item.answer == null" class="bx bx-x text-danger"></i>
                      </td>
                      <td>{{ item.comments || "No Comment." }}</td>
                    </tr>
                    <tr v-if="currentChecklist.items.length < 1">
                      <td colspan="2" class="text-center">No items available</td>
                    </tr>
                  </tbody>
                </table>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='clostViewChecklistModal'>Close</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="migrateJobSite" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Move job to another site</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Please note the site must already exist to move the job</p>
            <Multiselect v-model="job.site_id" id="migrateJobSiteSelect" valueProp="id" label="name" :searchable="true" :options="sites"></Multiselect>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeMigrateJobSite'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" @click="migrateJob" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* global $ */
import axios from 'axios';
import { mapGetters } from 'vuex';
import VTime from '../../../components/Time.vue';
import {DateTime} from 'luxon';
import PerfectScrollbar from 'perfect-scrollbar';
import SecureImage from '../../../components/SecureImage.vue';
import { saveAs } from 'file-saver';
import NewJobForm from "./NewJobForm";
import FileManager from "../../../components/FileManager";
import Multiselect from '@vueform/multiselect'
import SubmitButton from '../../../components/SubmitButton.vue';

export default {
  name: 'admin.jobs.view',
  props: ['id'],
  components: {
    FileManager,
    NewJobForm,
    VTime,
    SecureImage,
    Multiselect,
    SubmitButton
  },
  data(){
    return {
      job: {},
      site: {},
      sites: [],
      client: {},
      notes: [],
      note: "",
      users: [],
      availableUsers: [],
      newUser: null,
      images: [],
      toggles: {
        editDescription: false,
        editCompletedWorks: false,
        editNotCompletedWorks: false,
        editReportedBy: false,
        editQuoteNumber: false
      },
      logs: [],
      currentImage: {},
      newJob: {
        site_id: null,
        description: null
      },
      linkedJobs: [],
      loadingRelatedJobs: true,
      relatedJobs: [],
      endpoint: process.env.VUE_APP_API_URL,
      userFilter: "",
      checklists: [],
      currentChecklist: null
    }
  },
  mounted(){
    this.loadJob();
    this.loadChecklists();
    this.loadSites();
    new PerfectScrollbar(document.querySelector('.chat-content'));
    $('#fancy-file-upload').FancyFileUpload({
      url: `${process.env.VUE_APP_API_URL}/v1/jobs/${this.id}/images`,
      fileupload: {
        type: 'PUT',
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      },
			maxfilesize: 40000000,
      uploadcompleted: this.loadImages
		});
  },
  methods: {
    loadJob(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.id}`)
      .then(response => {
        this.job = response.data.job;
        this.logs = response.data.logs;
        if(this.job.status == null) this.job.status = 0;
        let m;
        let regex = /(#\d+)/gm
        while((m = regex.exec(this.job.description)) !== null){
          if (m.index === regex.lastIndex) {
            regex.lastIndex++;
          }
          m.forEach((match, groupIndex) => {
            console.log(`Found match, group ${groupIndex}: ${match}`);
            if(!this.linkedJobs.includes(match.replace('#', ''))){
              this.linkedJobs.push(match.replace('#', ''));
            }
          });
        }
        this.loadSite();
        this.loadUsers();
        this.loadNotes();
        this.loadImages();
      })
      .catch(error => {
        this.$error("Failed to load job.", error);
      })
    },
    focusUserFilter(){
      setTimeout(() => {
        document.getElementById('userFilterInput').focus();
      }, 500)
    },
    loadSites(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/sites/list`)
          .then(response => {
            this.sites = response.data.sites;
            this.sites.forEach((site) => {
              site.text = `(${site.client_name}) ${site.name}`;
              site.name = `(${site.client_name}) ${site.name}`;
            })
          })
          .catch(error => {
            this.$error("Failed to load sites", error);
          })
    },
    loadSite(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/sites/${this.job.site_id}`)
      .then(response => {
        this.site = response.data.site;
        this.loadClient();
      })
      .catch(error => {
        this.$error("Failed to load site.", error);
      })
    },
    loadClient(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/client/${this.site.client_id}`)
      .then(response => {
        this.client = response.data.client;
      })
      .catch(error => {
        this.$error("Failed to load client.", error);
      })
    },
    loadUsers(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.id}/users`)
      .then(response => {
        this.users = response.data.users;
        this.availableUsers = response.data.available;
      })
      .catch(error => {
        this.$error("Failed to load users.", error);
      })
    },
    addUser(user){
      let date = document.getElementById('date-' + user.id);
      date = date.value;

      if(!date){
        date = DateTime.local().toFormat('yyyy-MM-dd');
      }
      axios.put(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.id}/users`, {
        user_id: user.id,
        date: date
      })
      .then(() => {
        this.$success("Added user to job.");
        this.loadUsers()
      })
      .catch(error => {
        this.$error("Failed to assign user", error);
      })
    },
    removeUser(user){
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.id}/users`, {
        data: {
          user_id: user.id
        }
      })
      .then(() => {
        this.$success("Removed user from job.");
        this.loadUsers();
      })
      .catch(error => {
        this.$error("Failed to remove user from job", error);
      })
    },
    loadImages(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.id}/images`)
      .then(response => {
        response.data.images.forEach((i) => {
          if(i.path.includes('.mp4')){
            i.video = true;
          }
          if(i.path.includes('.mov')){
            i.video = true;
          }
        });
        this.images = response.data.images;
      })
      .catch(error => {
        this.$error("Failed to load images", error);
      })
    },
    deleteImage(image){
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.id}/images/${image.id}`)
      .then(() => {
        this.loadImages()
      })
      .catch(error => {
        this.$error("Failed to delete image.", error);
      })
    },
    loadNotes(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.job.id}/notes`)
      .then(response => {
        this.notes = response.data.notes;
        let objDiv = document.querySelector('.chat-content');
        this.$nextTick(() => {
          objDiv.scrollTop = objDiv.scrollHeight;
        })
      })
      .catch(error => {
        this.$error("Failed to load notes.", error);
      })
    },
    createNote(){
      if(this.note === undefined) {
        this.$warning("Please enter a note");
        return;
      }
      if(this.note.length < 1){
        this.$warning("Please enter a note");
        return;
      }
      axios.put(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.job.id}/notes`, {
        note: this.note
      })
      .then(() => {
        this.notes.push({
          user: {
            fname: this.user.fname,
            lname: this.user.lname,
            id: this.user.id
          },
          created_at: DateTime.local().toSeconds(),
          note: this.note
        });
        this.note = "";
      })
      .catch(error => {
        this.$error("Failed to add note", error);
      })
    },
    saveReportedBy(){
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.job.id}`, {
        reported_by: this.job.reported_by
      })
      .then(() => {
        this.$success("Saved changes to Reported By");
        this.toggles.editReportedBy = false;
      })
      .catch(error => {
        this.$error("Failed to save changes to Reported By", error);
      })
    },
    saveQuoteNumber(){
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.job.id}`, {
        quote_number: this.job.quote_number
      })
      .then(() => {
        this.$success("Saved changes to Quote Number");
        this.toggles.editQuoteNumber = false;
      })
      .catch(error => {
        this.$error("Failed to save changes to Quote Number", error);
      })
    },
    saveDescription(){
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.job.id}`, {
        description: this.job.description
      })
      .then(() => {
        this.$success("Saved changes to Job Summary");
        this.toggles.editDescription = false;
      })
      .catch(error => {
        this.$error("Failed to save changes to Job Summary", error);
      })
    },
    saveCompletionNotes(){
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.job.id}`, {
        completion_notes: this.job.completion_notes,
        not_completed_notes: this.job.not_completed_notes
      })
        .then(() => {
          this.$success("Saved changes to Job Summary");
          this.toggles.editDescription = false;
        })
        .catch(error => {
          this.$error("Failed to save changes to Job Summary", error);
        })
    },
    updateStatus(){
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.job.id}/status`, {
        status: this.job.status,
        note: "Manually updated status"
      })
      .then(() => {
        this.$success("Saved changes to Job Status");
      })
      .catch(error => {
        this.$error("Failed to save changes to Job Status", error);
      })
    },
    updateType(){
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.job.id}/type`, {
        type: this.job.type
      })
          .then(() => {
            this.$success("Saved changes to Job Type");
          })
          .catch(error => {
            this.$error("Failed to save changes to Job Type", error);
          })
    },
    updateInvoiced(){
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.job.id}/invoice`, {
        invoiced: this.job.invoiced
      })
      .then(() => {
          this.$success("Updated invoice");
      })
      .catch(error => {
        this.$error("Failed to update invoice", error);
      })
    },
    deleteJob(){
      //TODO: Check for assigned borises. If any, cannot delete!
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.job.id}`)
      .then(response => {
        if(response.status == 200){
          this.$success("Deleted Job");
          this.$router.replace('/p/jobs');
        }else{
          this.$warning("Failed to delete job!");
        }
      }).catch(error => {
        this.$error("Failed to delete job!", error);
      })
    },
    downloadJobSheet(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.job.id}/jobsheet`, {
        responseType: "blob"
      })
      .then(response => {
        saveAs(response.data, `Job Report ${this.job.id}.pdf`);
      })
      .catch(error => {
        this.$error("Failed to generate report", error);
      })
    },
    downloadJobClearance(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.job.id}/clearence/download`, {
        responseType: "blob"
      })
      .then(response => {
        saveAs(response.data, `Job Clearance ${this.job.id}.docx`);
      })
      .catch(error => {
        this.$error("Failed to generate report", error);
      })
    },
    openMaps(){
      let url = 'https://www.google.com/maps/search/?api=1&query=';
      let addressParts = [];
      if((this.site.street || "").length > 0) addressParts.push(this.site.street);
      if((this.site.town || "").length > 0) addressParts.push(this.site.town);
      if((this.site.county || "").length > 0) addressParts.push(this.site.county);
      if((this.site.postcode || "").length > 0) addressParts.push(this.site.postcode);
      let addressUrl = addressParts.join(', ');
      url += encodeURIComponent(addressUrl);
      window.open(url, '_blank').focus();
    },
    updateImage(image){
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.job.id}/images`, {
        image_id: image.id,
        name: image.name,
        description: image.description
      })
      .then(response => {
        if(response.data.success){
          this.$success("Updated image");
          image.edit = false;
        }
      })
      .catch(error => {
        this.$error("Failed to update image", error);
      })
    },
    showImage(image){
      this.currentImage = image;
      $('#imageViewModal').modal('toggle');
    },
    loadRelatedJobs(){

      this.loadingRelatedJobs = true;
      this.relatedJobs = [];
      $('#relatedJobsModal').modal('toggle');
      axios.get(`${process.env.VUE_APP_API_URL}/v1/jobs?page=1&site_id=${this.job.site_id}&counter=20`)
      .then(response => {
        this.relatedJobs = response.data.jobs.filter((j) => {
          return j.id !== this.job.id;
        });
      })
      .catch(error => {
        this.$error("Failed to load related jobs", error);
      })
      .finally(() => {
        this.loadingRelatedJobs = false;
      });

    },
    openJob(relatedJob){
      console.log(window.location);
      window.open(`/p/jobs/${relatedJob.id}`, '_blank').focus();
    },
    migrateJob(){
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/jobs/${this.id}/site`, {
        site: this.job.site_id
      })
      .then(() => {
        this.$success("Migrated job");
        this.loadJob();
        $('#closeMigrateJobSite').click();
      })
      .catch(error => {
        this.$error("Failed to migrate job!", error);
      })
    },
    removeJobChecklist(checklist){
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/checklist/${checklist.id}/assign/${this.job.id}`)
      .then(() => {
        this.$success("Removed checklist item");
        this.loadJob();
      })
      .catch(error => {
        this.$error("Failed to remove checklist item", error);
      })
    },
    loadChecklists(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/checklist?count=100`)
      .then(response => {
        this.checklists = response.data.checklists;
      })
      .catch(error => {
        this.$error("Failed to load checklists", error);
      })
    },
    assignChecklist(checklist){
      axios.post(`${process.env.VUE_APP_API_URL}/v1/checklist/${checklist.id}/assign`, {
        job_id: this.job.id
      })
      .then(() => {
        this.$success("Assigned checklist item");
        this.loadJob();
      })
      .catch(error => {
        this.$error("Failed to assign checklist item", error);
      })
    },
    openAssignChecklist(){
      $('#assignChecklistModal').modal('toggle');
    },
    openChecklist(checklist){
      this.currentChecklist = checklist;
      $('#viewChecklistModal').modal('toggle');
    },
  },
  computed: {
    ...mapGetters({
			user: 'auth/user',
      token: 'auth/token'
		}),
    numberOfContacts: function(){
      if(this.site.contacts){
        return this.site.contacts.length;
      }
      return 0;
    },
    filteredUsers: function(){
      return this.availableUsers.filter((user) => {
        return `${user.fname} ${user.lname}`.toLowerCase().includes(this.userFilter.toLowerCase());
      })
    },
    availableChecklists: function(){
      return this.checklists.filter((c) => {
        return !this.job?.checklists?.find((jc) => {
          return jc.id == c.id;
        })
      });
    }
  },
  watch: {
    id: function(){
      this.$router.go(0);
    }
  }
}
</script>

<style scoped>
  .modal-lg {
    max-width: 80%!important;
    max-height: 80%!important;
  }

  .modal-body {
    max-height: 100% !important;
  }

  .link:hover {
    border-bottom: 1px solid white;
  }

  pre {
    white-space: pre-wrap;
  }
</style>